<template>
    <transition name="fade">
        <section v-show="!loading">
            <StsSectionHeader :title="statusSectionTitle" />
            <StsLoanStatus :model="applicationStatus" />
        </section>
    </transition>
</template>

<script>
    import { createToastInterface } from 'vue-toastification';

    import { StsSectionHeader, StsLoanStatus } from '@/components/SwitchThink';
    import { toastConfig } from '@/utils';
    import showSpinner from '@/mixins/showSpinner';

    const toast = createToastInterface(toastConfig);

    export default {
        name: 'ApplicationStatus',
        props: {
            moduleName: {
                type: String,
                required: true
            },
            reload: {
                type: Boolean,
                required: true
            }
        },
        mixins: [showSpinner],
        components: {
            StsSectionHeader,
            StsLoanStatus
        },
        watch: {

            async reload(newVal, oldVal) {

                if (newVal !== oldVal && newVal) {

                    await this.load();
                    return this.$emit("reloaded");

                }
            }

        },
        data() {
            return {
                statusSectionTitle: '',
                applicationStatus: [
                    //Defaulting values in case there's an issue retrieving data from server
                    {
                        position: 1,
                        value: 'Approved',
                        icon: '',
                        active: false
                    },
                    {
                        position: 2,
                        value: 'Info Needed',
                        icon: '',
                        active: false
                    },
                    {
                        position: 3,
                        value: 'Processing',
                        icon: '',
                        active: false
                    },
                    {
                        position: 4,
                        value: 'Finalizing',
                        icon: '',
                        active: false
                    }
                ]
            };
        },
        methods: {
            async load() {
                try {
                    this.showSpinner({ loading: true });

                    const [statusSectionTitle, applicationStatus] = await Promise.all([
                        this.$store.dispatch(`${this.moduleName}/getStatusSectionTitle`),
                        this.$store.dispatch(`${this.moduleName}/getApplicationStatus`)
                    ]);

                    this.applicationStatus = applicationStatus;
                    this.statusSectionTitle = statusSectionTitle;

                    return this.showSpinner({ loading: false });
                } catch (error) {
                    this.showSpinner({ submitting: false });
                    return toast.error(error);
                }
            },
            async refresh() {
                return this.$emit("submit");
            }

        },
        async created() {
            await this.load();
        }
    };
</script>